<template>
    <main class="main-wrapper">
        <!-- Start Cart Area  -->
        <div class="axil-product-cart-area axil-section-gap">
            <div class="container">
                <div class="axil-product-cart-wrap">
                    <div class="product-table-heading">
                        <h4 class="title">{{ $t("Kosaram") }}</h4>
                        <a
                            href="#"
                            v-on:click.prevent="clearCart"
                            class="cart-clear"
                            >{{ $t("Kosár ürítése") }}</a
                        >
                    </div>
                    <div class="table-responsive">
                        <table
                            class="table axil-product-table axil-cart-table mb--40"
                        >
                            <thead>
                                <tr>
                                    <th scope="col" class="product-remove"></th>
                                    <th scope="col" class="product-thumbnail">
                                        {{ $t("Termék") }}
                                    </th>
                                    <th scope="col" class="product-title"></th>
                                    <th scope="col" class="product-price">
                                        {{ $t("Ár") }}
                                    </th>
                                    <th scope="col" class="product-quantity">
                                        {{ $t("Mennyiség") }}
                                    </th>
                                    <th scope="col" class="product-subtotal">
                                        {{ $t("Részösszeg") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in cart"
                                    v-bind:key="'cart-item-' + index"
                                >
                                    <td class="product-remove">
                                        <a
                                            href="#"
                                            class="remove-wishlist"
                                            v-on:click.prevent="
                                                deleteCartItem(index)
                                            "
                                            ><i class="fal fa-times"></i
                                        ></a>
                                    </td>
                                    <td class="product-thumbnail">
                                        <a href="javascript:void(0);"
                                            ><img
                                                v-if="
                                                    !item.image_uris ||
                                                        !item.image_uris.normal
                                                "
                                                src="/assets/images/product/electric/product-01.png"
                                                alt="Product Image"
                                            />
                                            <img
                                                v-else
                                                :src="item.image_uris.normal"
                                                :alt="item.name"
                                            />
                                        </a>
                                    </td>
                                    <td class="product-title">
                                        <a href="javascript:void(0);"
                                            >{{ item.name }}
                                            <i
                                                v-if="item.is_foil"
                                                class="fa fa-star"
                                            />
                                            <br />
                                            {{ item.set.toUpperCase() }} /
                                            {{ item.collector_number }}</a
                                        >
                                    </td>
                                    <td
                                        class="product-price"
                                        data-title="Price"
                                    >
                                        {{ item.price_formatted }}
                                    </td>
                                    <td
                                        class="product-quantity"
                                        data-title="Qty"
                                    >
                                        <div class="pro-qty">
                                            <input
                                                style="border: 1px solid #ccc"
                                                type="number"
                                                class="quantity-input"
                                                v-model="item.quantity"
                                            />
                                        </div>
                                    </td>
                                    <td
                                        class="product-subtotal"
                                        data-title="Subtotal"
                                    >
                                        {{
                                            Math.floor(item.price) *
                                                item.quantity
                                        }}
                                        Ft
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-xl-5 col-lg-7 offset-xl-7 offset-lg-5">
                            <div class="axil-order-summery mt--80">
                                <h5 class="title mb--20">
                                    {{ $t("Összegzés") }}
                                </h5>
                                <div class="summery-table-wrap">
                                    <table class="table summery-table mb--30">
                                        <tbody>
                                            <tr class="order-total">
                                                <td>{{ $t("Összesen") }}</td>
                                                <td class="order-total-amount">
                                                    {{ totalPayment }} Ft
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <a
                                    v-if="
                                        cart.length > 0 && totalPayment >= 500
                                    "
                                    href="#"
                                    v-on:click.prevent="goToCheckout"
                                    class="axil-btn btn-bg-primary checkout-btn"
                                    >{{ $t("Tovább a megrendelésre") }}</a
                                >
                                <a
                                    v-else
                                    disabled
                                    class="axil-btn btn-bg-primary checkout-btn"
                                    >{{ $t("Minimum rendelés: 500 Ft") }}</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Cart Area  -->
    </main>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "CartPage",
    beforeMount() {
        const self = this;
        const cart = this.$cookies.get("cart");

        ApiService.post(`/cart/update`, { cart: cart, no_popup: true }).then(
            resp => {
                self.cart = resp.data;
                self.isUpdating = false;
            }
        );
    },
    data() {
        return {
            cart: {}
        };
    },
    computed: {
        totalPayment() {
            let total = 0;
            for (const it in this.cart) {
                total +=
                    parseInt(this.cart[it].price) *
                    parseInt(this.cart[it].quantity);
            }

            return total;
        }
    },
    methods: {
        goToCheckout() {
            const self = this;
            const cart = this.cart;
            self.$root.$emit("showLoading", true);
            ApiService.post(`/cart/check`, { cart: cart })
                .then(() => {
                    ApiService.post(`/cart/update`, {
                        cart: cart,
                        no_popup: true
                    })
                        .then(resp => {
                            self.cart = resp.data;
                            let cHelp = {};
                            for (const it in self.cart) {
                                cHelp["c" + self.cart[it].sell_id] =
                                    self.cart[it].quantity;
                            }

                            self.$cookies.set(
                                "cart",
                                JSON.stringify(cHelp),
                                60 * 60 * 12
                            );
                            self.isUpdating = false;
                            self.$root.$emit("updateCart", true);

                            self.$router.push({ name: "Checkout" });
                        })
                        .finally(() => {
                            self.$root.$emit("hideLoading", true);
                        });
                })
                .finally(() => {
                    self.$root.$emit("hideLoading", true);
                });
        },
        clearCart() {
            this.$cookies.remove("cart");
            const self = this;
            const cart = this.$cookies.get("cart");

            ApiService.post(`/cart/update`, {
                cart: cart,
                no_popup: true
            }).then(resp => {
                self.cart = resp.data;
                self.isUpdating = false;
                self.$root.$emit("updateCart", true);
            });
        },
        deleteCartItem(index) {
            const self = this;
            self.cart.splice(index, 1);
            self.isUpdating = true;

            ApiService.post(`/cart/update`, { cart: self.cart }).then(resp => {
                self.cart = resp.data;
                let cHelp = {};
                for (const it in self.cart) {
                    cHelp["c" + self.cart[it].sell_id] = self.cart[it].quantity;
                }

                self.$cookies.set("cart", JSON.stringify(cHelp), 60 * 60 * 12);
                self.isUpdating = false;
                self.$root.$emit("updateCart", true);
            });

            this.$gtag.event("remove_from_cart", {
                event_category: "ecommerce",
                event_label: "remove_from_cart",
                value: index
            });
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        jQuery(".cart-close").click();
    }
};
</script>

<style scoped></style>
